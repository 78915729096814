import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  MagnifyingGlassIcon,
  RectangleStackIcon,
  LightBulbIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

function FooterNavigation() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div>
      {!location.pathname.match(/(login|app)/) && (
        <>
          <div className="h-16 lg:hidden relative z-[999999]" />
          <div className="w-full mx-auto px-2 h-16 bg-black text-white drop-shadow fixed z-10 bottom-0 lg:hidden sm:px-6 lg:px-8">
            <div className="grid grid-cols-4 h-full content-center">
              <Link to="/">
                <div className="flex flex-col items-center">
                  <HomeIcon className="block h-6" aria-hidden="true" />
                  <span className="text-xs">{t('Home')}</span>
                </div>
              </Link>

              <Link to="/browse">
                <div className="flex flex-col justify-items-center">
                  <MagnifyingGlassIcon className="block h-6" aria-hidden="true" />
                  <span className="text-xs">{t('Browse')}</span>
                </div>
              </Link>

              <Link to="/categories">
                <div className="flex flex-col justify-items-center">
                  <RectangleStackIcon className="block h-6" aria-hidden="true" />
                  <span className="text-xs">{t('Categories')}</span>
                </div>
              </Link>

              <Link to="/solutions">
                <div className="flex flex-col justify-items-center">
                  <LightBulbIcon className="block h-6" aria-hidden="true" />
                  <span className="text-xs">{t('Solutions')}</span>
                </div>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default FooterNavigation;
