import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 457 424"
    >
      <path
        fillRule="nonzero"
        d="M169.68 264.88c-25.199 7.84-43.68 28-43.68 52.641 0 24.078 18.48 44.801 43.68 52.641 2.238.559 3.922 2.238 5.039 4.481 25.762 64.398 94.641 110.88 175.28 110.88s149.52-46.48 175.28-110.88c1.121-2.239 2.801-3.922 5.039-4.481 25.199-7.84 43.68-28 43.68-52.641 0-24.078-18.48-44.801-43.68-52.641-2.238-.559-3.922-2.238-5.039-4.481-16.801-42-52.078-76.159-96.879-95.199-6.16-2.8-12.879 2.801-11.199 9.52 1.121 5.602 2.238 10.641 2.238 16.801 0 38.641-31.359 70-70 70-24.641 0-44.801-21.84-42-47.039 2.801-21.281 21.84-36.961 43.68-36.961 6.719 0 12.32 5.602 12.32 12.32v3.359c0 6.719-5.601 12.32-12.32 12.32h-1.121c-7.84 0-14.559 6.719-14 14.559.559 7.841 7.84 13.441 15.68 12.879 22.398-1.121 40.32-19.602 40.32-42 0-22.961-18.48-42-42-42-20.719 0-38.078-15.121-41.441-35.281-.558-3.922-3.922-6.718-7.281-6.718h-12.879c-4.48 0-8.398 3.921-7.84 8.96l2.242 18.48c.559 2.801-2.8 4.481-5.039 2.239-13.441-15.121-22.961-34.16-25.199-54.879-.558-3.922-3.922-6.719-8.961-6.719h-11.762c-4.48 0-7.839 3.922-7.839 8.398 2.8 29.121 16.238 56 35.84 75.602 3.922 3.922 2.8 10.078-1.68 12.32-38.641 19.602-68.879 50.961-84 89.039-.559 2.243-2.238 3.922-4.481 4.481h.002zm258.72 70.562c-13.441 2.801-24.641-8.961-21.84-21.84 1.121-7.281 7.281-13.441 14.559-14.559 12.879-2.238 24.641 8.961 21.84 21.84-1.117 7.277-7.277 13.438-14.559 14.559zm-10.078 68.32c-39.199 33.602-98 33.602-137.2 0-3.359-2.801-3.359-7.84-.559-11.199l7.84-7.84c3.922-3.922 8.399-3.922 11.199-1.68 14 11.762 31.359 17.922 49.84 17.922 18.48 0 35.84-6.16 49.84-17.922 2.801-2.8 7.281-2.238 11.199 1.68l7.84 7.84c3.918 3.359 3.918 8.398 0 11.199h.001zm-161.28-82.883c-2.238-12.879 8.961-24.078 21.84-21.84 7.281 1.121 13.441 7.281 14.559 14.559 2.238 13.441-8.961 24.641-21.84 21.84-7.277-1.117-13.438-7.277-14.559-14.559z"
        transform="translate(-121 -69)"
      />
    </svg>
  );
}

export default Icon;
